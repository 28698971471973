import React from 'react';
import ImageGrid from '../components/ImageGrid';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { profile } from '../images';

const SecondPage = () => (
  <Layout>
    <SEO title="Profile" description="List of my previous exhibitions" />
    <div className="row">
      <div className="block-content">
        <p>
          <strong>Group Exhibitions</strong>
        </p>
        <ul className="no-bullet">
          <li>2018 - Foundation Degree 2018 Student Exhibition / Highlands College / Jersey</li>
          <li>2017 - Foundation Degree 2017 Student Exhibition / Highlands College / Jersey</li>
          <li>2017 - Made With Love / Jersey Arts Centre&apos;s Berni Gallery/ Jersey</li>
          <li>2016 - Foundation Degree 2016 Student Exhibition / Highlands College / Jersey</li>
          <li>2015 - Christmas cheer / Harbour Gallery / Jersey</li>
          <li>2015 - Summer Exhibition - Jersey Icons, Memories of Jersey / Harbour Gallery / Jersey</li>
          <li>2015 - Organised Chaos / Harbour Gallery / Jersey</li>
          <li>2015 - Foundation Degree 2015 Student Exhibition / Highlands College / Jersey</li>
        </ul>
        <ImageGrid imagesFilter={profile} />
      </div>
    </div>
  </Layout>
);

export default SecondPage;
